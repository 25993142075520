<template>
  <div>
    <p class="endorsement-title mb-0 pb-0 d-flex mx-3">
      Tedarikçi - 2. Kalite Karşılaştırma Oranı

      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-if="!chartLoading"
      v-on:click="resizeBool ? null : resize()"
      class="mt-0 pt-0"
    >
      <apexchart
        type="donut"
        :height="height"
        :options="chartOptions"
        :series="series"
        :width="tmpWidth"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "SecondQualityDonutChartReport",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      chartCategories: [],
      resizeBool: null,
      tmpSeries: [],
      series: [],
      labels: [],
      height: 235,
      tmpWidth: null,
      chartLoading: false,
    };
  },

  props: ["startDate", "endDate", "onClickButton", "filters"],
  mounted() {
    this.getItems();
  },
  computed: {
    chartOptions() {
      let self = this;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth / 2;
      } else {
        this.tmpWidth = window.innerWidth / 3;
      }
      let chartOptions = {
        chart: {
          type: "donut",
        },
        colors: ["#1FE757", "#FD1515", "#E8ED0C"],
        title: {
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: ["Tedarikçi Kapama", "2. Kalite Kapama'", "Fire Oranı"],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      };
      return chartOptions;
    },
  },

  methods: {
    resize() {
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth / 2;
        this.$emit("resizecard", "piechart");
      } else {
        this.height = 235;
        this.tmpWidth = window.innerWidth / 3;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;
      this.series = [];
      this.chartCategories = [];
      let filters = this.filters;
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.endDate).format(LARAVEL_DATE_FORMAT)
      );

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/quality-closing-donut-chart-report",
        })
        .then((result) => {
          this.chartLoading = false;
          if (result.status) {
            let tmpData = result.data;
            this.series = [
              tmpData.supplier_total,
              tmpData.manager_total,
              tmpData.fire_total,
            ];
          } else {
            let tmpresult = result.data.response.data;
            this.sweetAlertError(tmpresult.message);
          }
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if (this.chartLoading != true) {
          this.series = [];
          this.labels = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 200px;
}
</style>
